<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap24AnonymousFunctionsAndClosures"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 24: Anonymous functions & closures" image-name="anonymous.jpg" image-alt="Anonymous functions & closures"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is an anonymous function?</p></li>
<li><p>How to create an anonymous function.</p></li>
<li><p>How to call an anonymous function.</p></li>
<li><p>What is a closure?</p></li>
<li><p>What is a function type?</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Anonymous function</p></li>
<li><p>Function literal</p></li>
<li><p>Lexical context</p></li>
<li><p>Scope</p></li>
</ul>
<div id="anonymous-functions" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Anonymous functions <a href="#anonymous-functions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>An anonymous function is similar to a traditional function except that the programmers did not give that function a name.</p>
<p>To create an anonymous function, we use a <strong>function literal :</strong></p>
<pre v-highlightjs><code class="go" v-pre >// anonymous/anon/main.go
package main

import &quot;fmt&quot;

func main() {
    // an anonymous function
    func() {
        // the instructions of the anonymous function
        fmt.Println(&quot;I am anonymous function&quot;)
    }()
}</code></pre>
<p>Here we define a function with no name by using the syntax <span v-highlightjs><code class="go" v-pre style="display: inline">func(){..}</code></span> and we execute it by adding the two parenthesis <span v-highlightjs><code class="go" v-pre style="display: inline">()</code></span>.</p>
<p>So to define an anonymous function and execute it just after, you can use the following syntax :</p>
<pre v-highlightjs><code class="go" v-pre >func(){...}()</code></pre>
<ul>
<li><p>To define the function, use the syntax : <span v-highlightjs><code class="go" v-pre style="display: inline">func(){..}</code></span></p></li>
<li><p>To define and run it : <span v-highlightjs><code class="go" v-pre style="display: inline">func(){..}()</code></span></p></li>
</ul>
<p>Let’s take another example</p>
<pre v-highlightjs><code class="go" v-pre >// anonymous/example-2/main.go
package main

import (
    &quot;fmt&quot;
    &quot;reflect&quot;
)

func main() {

    /**
     * func literal not executed
     */
    myFunc := func() int {
        fmt.Println(&quot;I am a func litteral&quot;)
        return 42
    }
    // Output : nothing
    // the function is not executed

    fmt.Println(reflect.TypeOf(myFunc))
    // Output : func() int

    /**
     * func literal invoked
     */
    funcValue := func() int {
        fmt.Println(&quot;I am a func literal invoked&quot;)
        return 42

    }()
    // Output: I am a func literal invoked
    // the func is executed!
    fmt.Println(reflect.TypeOf(funcValue))
    // Output : int

}</code></pre>
<p>What is the most important thing here? Just remember that a function can have no name; you can store a function (not its result) but the function itself into a variable. This last thing causes a lot of trouble to many developers. We are confused because we are used to storing values and not functions inside variables!</p>
<div id="function-types" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Function types <a href="#function-types"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>You can define a type based on a function signature. Here is an example taken from the gin module (https://github.com/gin-gonic/gin)</p>
<pre v-highlightjs><code class="go" v-pre >package gin

//...

type HandlerFunc func(*Context)

//...</code></pre>
<p>And here is an example taken from the standard http package :</p>
<pre v-highlightjs><code class="go" v-pre >package http
// ...
type HandlerFunc func(ResponseWriter, *Request)

//...</code></pre>
<p>A function type designates all functions with the same parameters and results.<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>. Once you have defined your type, you can create a variable that has a function type. Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >type Funky func(string)

var f Funky
f = func(s string){
    // my function defined
    log.Printf(&quot;Funky %s&quot;,s)
}
f(&quot;Groovy&quot;)</code></pre>
<ul>
<li>We define a new exported type <span v-highlightjs><code class="go" v-pre style="display: inline">Funky</code></span>.</li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Funky</code></span> designate all functions with a single string parameter and no results. (<span v-highlightjs><code class="go" v-pre style="display: inline">func(string)</code></span>)</p>
<ul>
<li><p>We create a variable <span v-highlightjs><code class="go" v-pre style="display: inline">f</code></span> of type <span v-highlightjs><code class="go" v-pre style="display: inline">Funky</code></span>.</p></li>
<li><p>Then we assign to the variable <span v-highlightjs><code class="go" v-pre style="display: inline">f</code></span> an anonymous function</p></li>
<li><p>And then, we execute <span v-highlightjs><code class="go" v-pre style="display: inline">f</code></span> with <span v-highlightjs><code class="go" v-pre style="display: inline">f("Groovy")</code></span></p></li>
</ul>
<div id="functions-are-first-class-citizens" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Functions are “first-class citizens” <a href="#functions-are-first-class-citizens"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In some programming languages<a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a> functions are <strong>“first-class citizens”</strong><a href="#fn3" class="footnote-ref" id="fnref3" role="doc-noteref"><sup>3</sup></a> allowing them to be :</p>
<ul>
<li><p>Used a <strong>parameters</strong></p></li>
<li><p>Used as <strong>results</strong></p></li>
<li><p><strong>Assigned</strong> to a variable</p></li>
</ul>
<p>Go functions are considered as first-class objects. It means that :</p>
<ul>
<li><p>Functions can be passed as arguments to other functions</p></li>
<li><p>You can return functions from other functions</p></li>
<li><p>You can bind functions to variables</p></li>
</ul>
<div id="closures" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Closures <a href="#closures"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>An <strong>anonymous function</strong> defined into another function <span v-highlightjs><code class="go" v-pre style="display: inline">F</code></span> can use elements that are not defined in its scope but in the scope of <span v-highlightjs><code class="go" v-pre style="display: inline">F</code></span>.</p>
<p>Anonymous functions can keep references to its surrounding state. A closure is formed when a function references variables not defined in its own scope.<a href="#fn4" class="footnote-ref" id="fnref4" role="doc-noteref"><sup>4</sup></a></p>
<div id="example" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> Example <a href="#example"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Here is an example of a closure :</p>
<pre v-highlightjs><code class="go" v-pre >// anonymous/first-closure/main.go
package main

import &quot;fmt&quot;

func printer() func() {
    k := 1
    return func() {
        fmt.Printf(&quot;Print n. %d\n&quot;, k)
        k++
    }
}

func main() {

    p := printer()
    p()
    // Print n. 1
    p()
    // Print n. 2
    p()
    // Print n. 3
}</code></pre>
<p>We have a function that is called <span v-highlightjs><code class="go" v-pre style="display: inline">printer</code></span><strong>.</strong> The signature of this function is <span v-highlightjs><code class="go" v-pre style="display: inline">printer() func()</code></span>. This means that this function will return itself a function. <span v-highlightjs><code class="go" v-pre style="display: inline">func()</code></span> means that we return a function that returns nothing. We could have the following syntax as return type : <span v-highlightjs><code class="go" v-pre style="display: inline">func() int</code></span> this would mean that our returned function will return itself an integer. Let’s jump into the declaration of the function <span v-highlightjs><code class="go" v-pre style="display: inline">printer</code></span>. First, we define the variable <span v-highlightjs><code class="go" v-pre style="display: inline">k</code></span> which is initialized with the value <strong>1.</strong> And then we return an anonymous function. Into this function, we use <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Printf</code></span>. to display into the standard output <span v-highlightjs><code class="go" v-pre style="display: inline">"Print n. %d\n"</code></span> where <span v-highlightjs><code class="go" v-pre style="display: inline">%d</code></span> is replaced by the value of <span v-highlightjs><code class="go" v-pre style="display: inline">k</code></span>.</p>
<p>Here we :</p>
<ul>
<li><p>define the variable <span v-highlightjs><code class="go" v-pre style="display: inline">k</code></span> in the outer function</p></li>
<li><p>we return a new anonymous function that makes use of <span v-highlightjs><code class="go" v-pre style="display: inline">k</code></span>.</p></li>
</ul>
<p>Hence we have created a <strong>closure</strong>.</p>
<ul>
<li><p>Then in the main function, we will assign to <span v-highlightjs><code class="go" v-pre style="display: inline">p</code></span> the return value of printer (which is a function). After that, we will use <span v-highlightjs><code class="go" v-pre style="display: inline">p</code></span>.</p></li>
<li><p>Remember that <span v-highlightjs><code class="go" v-pre style="display: inline">p</code></span> is a function, so to execute it, we have just to add parenthesis at the end :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >p() // we execute p, the closure</code></pre>
<p>The type of p is <span v-highlightjs><code class="go" v-pre style="display: inline">func()</code></span><strong>.</strong></p>
<div id="scopes" class="anchor"></div>
<h2 data-number="6.2"><span class="header-section-number">6.2</span> Scopes <a href="#scopes"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>We say that this anonymous function captures it’s <strong>lexical context</strong>.</p></li>
<li><p>The function keeps a memory of the variables that are used in its environment.</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/closure.png')" alt="Inner and outer scope[fig:Inner-and-outer]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Inner and outer scope<span id="fig:Inner-and-outer" label="fig:Inner-and-outer">[fig:Inner-and-outer]</span></figcaption>
</figure>
<ul>
<li><p>The <strong>scope of a function</strong> (or lexical environment) is a region where you can use all the variables (types, constants) that you have declared in it.</p></li>
<li><p>The enclosing function defines an <strong>outer</strong> scope. In our case, we define the variable <span v-highlightjs><code class="go" v-pre style="display: inline">k</code></span> <strong>in the outer scope</strong> of the enclosing function. <span v-highlightjs><code class="go" v-pre style="display: inline">k</code></span> is then used in the returned function scope (the inner scope).</p></li>
<li><p>We use a variable in the inner scope that we declared in the outer scope.</p></li>
<li><p>The closures stores a reference to the variable that we defined in the outer scope.</p></li>
<li><p>That’s why when we execute our closure several time the value <span v-highlightjs><code class="go" v-pre style="display: inline">k</code></span> is incremented. The closure holds the reference, and each time we execute it, it will modify the underlying value.</p></li>
</ul>
<div id="some-common-use-cases-of-closures" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Some common use cases of closures <a href="#some-common-use-cases-of-closures"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="function-parameters" class="anchor"></div>
<h2 data-number="7.1"><span class="header-section-number">7.1</span> Function parameters <a href="#function-parameters"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can pass a function as a parameter of another function. In the sort package (part of the standard library), several functions accept functions as parameters :</p>
<pre v-highlightjs><code class="go" v-pre >// sort package

// Search for an index of a specific value using binary search
func Search(n int, f func(int) bool) int

// sort a slice by using the provided function (less)
func Slice(slice interface{}, less func(i, j int) bool)</code></pre>
<p>In the two previous signature, we see that <span v-highlightjs><code class="go" v-pre style="display: inline">sort.Search</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">sort.Slice</code></span> functions are requiring both a function to work. The function is used internally to do the sorting job.</p>
<p>Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >// anonymous/input-parameters/main.go
package main

import (
    &quot;fmt&quot;
    &quot;sort&quot;
)

func main() {
    scores := []int{10, 89, 76, 3, 20, 12}
    // ascending
    sort.Slice(scores, func(i, j int) bool { return scores[i] &lt; scores[j] })
    fmt.Println(scores)
    // Output : [3 10 12 20 76 89]

    // descending
    sort.Slice(scores, func(i, j int) bool { return scores[i] &gt; scores[j] })
    fmt.Println(scores)
    // Output : [89 76 20 12 10 3]
}</code></pre>
<ul>
<li><p>We define a variable <span v-highlightjs><code class="go" v-pre style="display: inline">scores</code></span>, which is a slice of integers. They represent the winning score for 6 “gophing” games (a new sport practiced only by gophers).</p></li>
<li><p>The <span v-highlightjs><code class="go" v-pre style="display: inline">scores</code></span> are not sorted at all. The objective is to sort them following an order.</p></li>
<li><p>We will use the function <span v-highlightjs><code class="go" v-pre style="display: inline">sort.Slice</code></span>.</p></li>
<li><p>Its signature requires a slice and a function. The function will define how the runtime will execute the sort. The functions take two parameters (integers); they represent two indexes. It returns a boolean.</p></li>
<li><p>We have here a closure because the function uses the variable <span v-highlightjs><code class="go" v-pre style="display: inline">scores</code></span>, even if it is not defined in its inner scope.</p></li>
</ul>
<p>In our first example, the less function is :</p>
<pre v-highlightjs><code class="go" v-pre >func(i, j int) bool { return scores[i] &lt; scores[j] }</code></pre>
<p>The element at index <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> will be placed before the element at index <span v-highlightjs><code class="go" v-pre style="display: inline">j</code></span> if the element at index <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> is <strong>less</strong> than the element at index <span v-highlightjs><code class="go" v-pre style="display: inline">j</code></span>.</p>
<p>As a result, our slice will be sorted in ascending order :</p>
<pre v-highlightjs><code class="go" v-pre >[3 10 12 20 76 89]</code></pre>
<p>In the second call to <span v-highlightjs><code class="go" v-pre style="display: inline">sort.Slice</code></span> we are giving as input the following function :</p>
<pre v-highlightjs><code class="go" v-pre >func(i, j int) bool { return scores[i] &gt; scores[j] }</code></pre>
<p>The element at index <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> will be placed before the element at index <span v-highlightjs><code class="go" v-pre style="display: inline">j</code></span> if the element at index <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> is <strong>greater</strong> than the element at index <span v-highlightjs><code class="go" v-pre style="display: inline">j</code></span>. The result is a descending ordered slice :</p>
<pre v-highlightjs><code class="go" v-pre >[89 76 20 12 10 3]</code></pre>
<div id="wrappers-http.handlefunc" class="anchor"></div>
<h2 data-number="7.2"><span class="header-section-number">7.2</span> Wrappers : http.HandleFunc <a href="#wrappers-http.handlefunc"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>We can use closures to wrap other functions.</p>
<p>Before explaining the technique, we will build a simple web server (you can skip the paragraph if you already know how to do it)</p>
<div id="setting-of-a-basic-web-server" class="anchor"></div>
<h3 data-number="7.2.1"><span class="header-section-number">7.2.1</span> Setting of a basic web server <a href="#setting-of-a-basic-web-server"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>In the following code block, we are specifying that when a request is sent to the endpoint <span v-highlightjs><code class="go" v-pre style="display: inline">"/homepage"</code></span> the function <span v-highlightjs><code class="go" v-pre style="display: inline">homepageHandler</code></span> will be executed.</p>
<pre v-highlightjs><code class="go" v-pre >// anonymous/wrappers/main.go 

func homepageHandler(writer http.ResponseWriter, request *http.Request) {
  fmt.Fprintln(writer, &quot;Welcome to my homepage&quot;)
  fmt.Fprintln(writer, &quot;I am max&quot;)
}</code></pre>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">homepageHandler</code></span> function takes a response writer (<span v-highlightjs><code class="go" v-pre style="display: inline">http.ResponseWriter</code></span>) (to prepare the response) and the request (<span v-highlightjs><code class="go" v-pre style="display: inline">*http.Request</code></span>).</p>
<p>Here is a minimal web server example :</p>
<pre v-highlightjs><code class="go" v-pre >// anonymous/wrappers/main.go 

package main

import (
    &quot;fmt&quot;
    &quot;net/http&quot;
)

func main() {
    http.HandleFunc(&quot;/homepage&quot;, homepageHandler)
    err := http.ListenAndServe(&quot;:3000&quot;, nil)
    if err != nil {
        log.Fatal(err)
    }

}
func homepageHandler(writer http.ResponseWriter, request *http.Request) {
    fmt.Fprintln(writer, &quot;Welcome to my homepage&quot;)
    fmt.Fprintln(writer, &quot;I am max&quot;)

}</code></pre>
<div id="the-wrapper-technique" class="anchor"></div>
<h3 data-number="7.2.2"><span class="header-section-number">7.2.2</span> The wrapper technique <a href="#the-wrapper-technique"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Imagine that your web server has several endpoints. and you want to keep track of visits on each endpoint.</p>
<p>The brutal way to do this would be to add a counter in each of your handlers.</p>
<p>But there is a clever way: you can create a generic wrapper that all your handlers can use. Your wrapper should take as input a handler and return a handler. That way, you can have the following syntax :</p>
<pre v-highlightjs><code class="go" v-pre >http.HandleFunc(&quot;/homepage&quot;, trackVisits(homepageHandler))</code></pre>
<p>This is elegant! We understand that here we track the visits and then we execute the handler. Let’s implement the <span v-highlightjs><code class="go" v-pre style="display: inline">trackVisits</code></span> function :</p>
<pre v-highlightjs><code class="go" v-pre >func trackVisits(handler func(http.ResponseWriter, *http.Request)) func(http.ResponseWriter, *http.Request) {
    return func(writer http.ResponseWriter, request *http.Request) {
        // track the visit
        fmt.Println(&quot;one visit !&quot;)
        // call the original handler
        handler(writer, request)
    }
}</code></pre>
<ul>
<li><p>The <span v-highlightjs><code class="go" v-pre style="display: inline">trackVisits</code></span> function takes a <span v-highlightjs><code class="go" v-pre style="display: inline">handler</code></span> as parameter and returns a <span v-highlightjs><code class="go" v-pre style="display: inline">handler</code></span>.</p></li>
<li><p>The return type of <span v-highlightjs><code class="go" v-pre style="display: inline">trackVisits</code></span> must have the same signature as the one expected by the second input parameter of <span v-highlightjs><code class="go" v-pre style="display: inline">http.HandleFunc</code></span><strong>.</strong></p></li>
<li><p>In the function body, we are returning an anonymous function. This anonymous function will first print that we have one visit and then launch the original handler’s execution.</p></li>
</ul>
<p>This technique is very powerful. It gives us the power to create generic wrappers that we can use on several endpoints: it avoids code duplication.</p>
<pre v-highlightjs><code class="go" v-pre >http.HandleFunc(&quot;/homepage&quot;, trackVisits(homepageHandler))
http.HandleFunc(&quot;/cv&quot;, trackVisits(cvHandler))
http.HandleFunc(&quot;/projects&quot;, trackVisits(projectsHandler))</code></pre>
<p>Is it also possible to chain the wrappers :</p>
<pre v-highlightjs><code class="go" v-pre >http.HandleFunc(&quot;/projects&quot;, authenticate(trackVisits(projectsHandler)))</code></pre>
<p>by defining other wrappers :</p>
<pre v-highlightjs><code class="go" v-pre >func authenticate(handler func(http.ResponseWriter, *http.Request)) func(http.ResponseWriter, *http.Request) {
    return func(writer http.ResponseWriter, request *http.Request) {
        // check that request is authenticated
        fmt.Println(&quot;authenticated !&quot;)
        // call the original handler
        handler(writer, request)
    }
}</code></pre>
<div id="functional-options-pattern" class="anchor"></div>
<h2 data-number="7.3"><span class="header-section-number">7.3</span> Functional options pattern <a href="#functional-options-pattern"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Dave Cheney has described this pattern<a href="#fn5" class="footnote-ref" id="fnref5" role="doc-noteref"><sup>5</sup></a>. It is a pattern that allows you to improve the API of your libraries.</p>
<p>Usually, a library offers several “options”. With this pattern, options are provided with functions.</p>
<div id="example-1" class="anchor"></div>
<h3 data-number="7.3.1"><span class="header-section-number">7.3.1</span> Example <a href="#example-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Let’s take an example of a fake authentication library :</p>
<pre v-highlightjs><code class="go" v-pre >auth := authenticator.New(&quot;test&quot;, authenticator.UnverifiedEmailAllowed(), authenticator.WithCustomJwtDuration(time.Second))

if auth.IsValidJWT(&quot;invalid&quot;) {
    log.Println(&quot;mmmm... maybe we should use another lib.&quot;)
}</code></pre>
<ul>
<li><p>We call <span v-highlightjs><code class="go" v-pre style="display: inline">authenticator.New</code></span> to initialize a new authenticator</p></li>
<li><p>We pass to the function a string <span v-highlightjs><code class="go" v-pre style="display: inline">"test"</code></span> and two other parameters, which are</p>
<pre v-highlightjs><code class="go" v-pre >authenticator.UnverifiedEmailAllowed()

authenticator.WithCustomJwtDuration(time.Second)</code></pre></li>
<li><p>Those two additional parameters are function calls that will return a value.</p></li>
</ul>
<p>You see here that options are set with functions.</p>
<div id="how-is-it-made-inside" class="anchor"></div>
<h3 data-number="7.3.2"><span class="header-section-number">7.3.2</span> How is it made inside? <a href="#how-is-it-made-inside"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// anonymous/functionalOptions/authenticator/authenticator.go 
package authenticator

import (
    &quot;time&quot;
)

type options struct {
    jwtDuration          time.Duration
    allowUnverifiedEmail bool
    allowUnverifiedPhone bool
}

type Option func(*options)

func WithCustomJwtDuration(duration time.Duration) Option {
    return func(options *options) {
        options.jwtDuration = duration
    }
}

func UnverifiedEmailAllowed() Option {
    return func(options *options) {
        options.allowUnverifiedEmail = true
    }
}

func UnverifiedPhoneAllowed() Option {
    return func(options *options) {
        options.allowUnverifiedEmail = true
    }
}

type Authenticator struct {
    name    string
    options options
}

func New(name string, opts ...Option) *Authenticator {
    options := options{
        jwtDuration: time.Hour,
    }
    for _, opt := range opts {
        opt(&amp;options)
    }
    return &amp;Authenticator{name: name, options: options}
}

func (a Authenticator) IsValidJWT(jwt string) bool {
    return false
}</code></pre>
<ul>
<li><p>First an <span v-highlightjs><code class="go" v-pre style="display: inline">options</code></span> struct is created</p>
<ul>
<li><p>This struct will keep track of the options set.</p></li>
<li><p>Each option is a field (unexported).</p></li>
</ul></li>
<li><p>A function type is created <span v-highlightjs><code class="go" v-pre style="display: inline">type Option func(*options)</code></span></p></li>
<li><p>For each option, we create an exported function that will return an <span v-highlightjs><code class="go" v-pre style="display: inline">Option</code></span></p>
<ul>
<li>For the option “allow unverified email” we have the following function :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >func UnverifiedEmailAllowed() Option {
    return func(options *options) {
        options.allowUnverifiedEmail = true
    }
}</code></pre></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">New</code></span> will take as second parameter <span v-highlightjs><code class="go" v-pre style="display: inline">Option</code></span>s</p>
<ul>
<li><p>This function is <strong>variadic</strong>, because it can be invoked with zero or more arguments of type <span v-highlightjs><code class="go" v-pre style="display: inline">Option</code></span>.</p></li>
<li><p>Inside <span v-highlightjs><code class="go" v-pre style="display: inline">New</code></span> :</p>
<ul>
<li><p>First, the default values are set.</p></li>
<li><p>Then we iterate over <span v-highlightjs><code class="go" v-pre style="display: inline">opts</code></span> (which is a slice of <span v-highlightjs><code class="go" v-pre style="display: inline">Option</code></span>)</p></li>
<li><p>At each iteration, we get a function that set an option</p></li>
<li><p>We simply execute the function with <span v-highlightjs><code class="go" v-pre style="display: inline">opt(&amp;options)</code></span></p></li>
</ul></li>
</ul>
<div id="test-yourself" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<h2 class="unnumbered" id="questions">Questions</h2>
<ol type="1">
<li><p>True or False? You cannot assign an anonymous function to a variable.</p></li>
<li><p>True or False? You cannot define a named function inside a function</p></li>
<li><p>True or False? You cannot define an anonymous function inside a function</p></li>
<li><p>Fill in the blank : “An ______ can be declared with a type ____.”</p></li>
<li><p>Can an anonymous function use a variable not defined in its body or parameter list?</p></li>
</ol>
<h2 class="unnumbered" id="answers">Answers</h2>
<ol type="1">
<li><p>True or False? You cannot assign an anonymous function to a variable.</p>
<ol type="1">
<li><p>False</p></li>
<li><p>You can assign an anonymous function to a variable</p></li>
</ol>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >f35 := func()uint{ return 35}</code></pre></li>
<li><p>True or False? You cannot define a named function inside a function</p>
<ol type="1">
<li><p>This is true</p></li>
<li><p>The following snippets won’t compile :</p></li>
</ol>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >func main(){
    func insideFunction(){

    }
}

func main(){
    f := func(s string){
        func insideFunction(){

        }
        test()
    }
}</code></pre></li>
<li><p>True or False ? You cannot define an anonymous function inside a function</p>
<ol type="1">
<li>False</li>
</ol></li>
<li><p>Fill in the blank : “An ______ can be declared with a type ____.”</p>
<ol type="1">
<li><u>An anonymous function</u> can be declared with a type <u>literal</u>.</li>
</ol></li>
<li><p>Name one common use case of closures.</p>
<ol type="1">
<li>Wrapping handlers</li>
</ol></li>
<li><p>Can an anonymous function use a variable not defined in it’s body or parameter list?</p>
<ol type="1">
<li><p>Yes</p></li>
<li><p>An anonymous function can use elements defined in its enclosing function.</p></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>To define an anonymous function, use a function literal</p>
<pre v-highlightjs><code class="go" v-pre >func(){ fmt.Println(&quot;my anonymous function&quot;)}</code></pre></li>
<li><p>A function literal can be assigned to a variable</p>
<pre v-highlightjs><code class="go" v-pre >myFunc := func(){ fmt.Println(&quot;my anonymous function&quot;)}</code></pre></li>
<li><p>To <strong>define &amp; immediately call</strong> an anonymous function use parenthesis :</p>
<pre v-highlightjs><code class="go" v-pre >func(){ fmt.Println(&quot;my anonymous function&quot;)}()</code></pre>
<ul>
<li>Here we define and execute this anonymous function</li>
</ul></li>
<li><p>A function type designate all functions with the same parameters and results</p>
<ul>
<li><p>The zero value of the function type is nil</p></li>
<li><p>Ex :</p>
<pre v-highlightjs><code class="go" v-pre >type Option func(*options)</code></pre></li>
</ul></li>
<li><p>Functions are “first-class citizen”.</p>
<ul>
<li><p>They can be passed as parameter</p></li>
<li><p>They can be returned as result</p></li>
<li><p>They can be assigned to a variable</p></li>
</ul></li>
<li><p>An anonymous function can use elements (variables, types, constants) that are defined in its surrounding scope</p>
<ul>
<li>We say that the anonymous function captures the lexical context of the surrounding function.</li>
</ul></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>https://golang.org/ref/spec#Function_types<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>Especially functional languages<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn3" role="doc-endnote"><p><strong>This term was introduced indirectly in 1967 by CHRISTOPHER STRACHEY, see<b-link class="citation" data-cites="strachey2000fundamental" href="#strachey2000fundamental" >[@strachey2000fundamental]</b-link>, section 3.5.1. First and second class objects</strong><a href="#fnref3" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn4" role="doc-endnote"><p>See : https://en.wikipedia.org/wiki/Closure_(computer_programming)#Lexical_environment, https://tour.golang.org/moretypes/25<a href="#fnref4" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn5" role="doc-endnote"><p>See: https://dave.cheney.net/2014/10/17/functional-options-for-friendly-apis and https://dave.cheney.net/2016/11/13/do-not-fear-first-class-functions<a href="#fnref5" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap24AnonymousFunctionsAndClosures"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap23Errors'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Errors</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap25JsonAndXml'}">
									<p><u><small>Next</small></u></p>
									<p><small>JSON and XML</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Anonymous functions & closures - Practical Go Lessons"
const description = "An anonymous function is similar to a traditional function except that the programmers did not give that function a name."

export default {
  name: "Chap24AnonymousFunctionsAndClosures",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
